import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import './styles.scss'

const Image = loadable(() => import('/src/components/Media/Image'))

const SingleSearchResult = ({ searchTerm, data }) => {
    const generateHighlights = (content) => {
        // This function adds <strong> tags around the search term within the text passed to it
        const re = new RegExp(searchTerm, 'i') // Generate regex so that the search term's capitalisation doesn't matter
        const search = content.search(re)

        if (searchTerm && search !== -1) {
            return `
                ${content.slice(0, search)}
                <strong>
                    ${content.slice(search, search + searchTerm.length)}
                </strong>
                ${content.slice(search + searchTerm.length)}
            `
        } else return content
    }

    return (
        <li className="c-search-results-item">
            {data.category.image && (
                <Image
                    data={data.category.image}
                    className={'c-search-results-item__icon'}
                />
            )}

            <div className="c-search-results-item__content">
                {data.title && (
                    <Link
                        to={data.uri}
                        className={'c-search-results-item__title'}
                    >
                        {parse(generateHighlights(data.title))}
                    </Link>
                )}

                {data.description && (
                    <p className="c-search-results-item__description">
                        {parse(generateHighlights(data.description))}
                    </p>
                )}

                {data.category.name && (
                    <div className="c-search-results-item__category">
                        Category:{' '}
                        <Link to={data.category.link}>
                            {data.category.name}
                        </Link>
                    </div>
                )}
            </div>
        </li>
    )
}

SingleSearchResult.propTypes = {
    searchTerm: PropTypes.string,
    data: PropTypes.shape({
        uri: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        category: PropTypes.shape({
            image: PropTypes.object,
            link: PropTypes.string,
            name: PropTypes.string
        })
    }).isRequired
}

SingleSearchResult.defaultProps = {
    data: []
}

export default SingleSearchResult
